import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Funnel, FunnelsQueryResponse} from './_models'
import {request} from '../../../../services/crm-api/request'

const getFunnels = async (query: string): Promise<FunnelsQueryResponse> => {
  return request(
    {
      method: 'GET',
      url: `/funnels?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((d: AxiosResponse<FunnelsQueryResponse>) => d.data)
}

const getFunnelById = (id: ID): Promise<Funnel | undefined> => {
  return request(
    {
      method: 'GET',
      url: `/funnels/${id}?include_teams=true&include_users=true`,
    },
    {
      setAuth: true,
    }
  ).then((response: Response<Funnel>) => response.data)
}

const createFunnel = (funnel: Funnel): Promise<Funnel | undefined> => {
  let data = {...funnel}
  if (data.type) {
    data.type = parseInt(data.type.toString())
  }
  if (data.status) {
    data.status = parseInt(data.status.toString())
  }

  return request(
    {
      method: 'post',
      url: `/funnels`,
      data: data,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Funnel>>) => response.data)
    .then((response: Response<Funnel>) => response.data)
}

const updateFunnel = (funnel: Funnel): Promise<Funnel | undefined> => {
  let data = {...funnel}
  if (data.status) {
    data.status = parseInt(data.status.toString())
  }

  return request(
    {
      method: 'patch',
      url: `/funnels/${funnel.id}`,
      data: data,
    },
    {
      setAuth: true,
    }
  )
    .then((response: AxiosResponse<Response<Funnel>>) => response.data)
    .then((response: Response<Funnel>) => response.data)
}

const getUsers = async (query: string): Promise<Response<Array<any>>> => {
  return request(
    {
      method: 'GET',
      url: `/users?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Response<Array<any>>>) => response.data)
}

const getTeams = async (query: string): Promise<Array<any>> => {
  return request(
    {
      method: 'GET',
      url: `/teams?${query}`,
    },
    {
      setAuth: true,
    }
  ).then((response: AxiosResponse<Array<any>>) => response.data)
}

const deleteFunnel = async (id: number) => {
  return await request(
    {
      method: 'delete',
      url: `/funnels/${id}`,
    },
    {
      setAuth: true,
    }
  ).then(() => {})
}

export {getFunnels, getFunnelById, createFunnel, updateFunnel, getUsers, getTeams, deleteFunnel}
